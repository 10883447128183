import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Broker, BrokerSimple, CreateBrokerRequest, PaginationMetaData } from '../type';
import { environment as env } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BrokerPerformanceAnalytic, BrokerPerformanceAnalyticRating } from '../../dashboard/insight/insight.type';

@Injectable()
export class BrokerService {
    public constructor(private readonly http: HttpClient) {}

    public createBroker(data: CreateBrokerRequest): Observable<Broker> {
        return this.http
            .post<{ data: Broker; status: string }>(`${env.baseServerUrl}/brokers`, data)
            .pipe(map(response => response.data));
    }

    public updateBroker(idBroker: number, data: CreateBrokerRequest & { phone?: string }): Observable<string> {
        return this.http
            .patch<{ status: string }>(`${env.baseServerUrl}/brokers/${idBroker}`, data)
            .pipe(map(response => response.status));
    }

    public brokersRequest(
        page: number,
        perPage: number,
    ): Observable<{ brokers: Broker[]; settings: PaginationMetaData }> {
        return this.http
            .get<{ data: Broker[]; status: string; meta: PaginationMetaData }>(`${env.baseServerUrl}/brokers`, {
                params: { per_page: perPage, page },
            })
            .pipe(
                map(response => ({
                    brokers: response.data,
                    settings: response.meta,
                })),
            );
    }

    public brokersDrillDownRequest(
        brokerId: number,
        params = {},
    ): Observable<{
        rate4: BrokerPerformanceAnalytic;
        rate5: BrokerPerformanceAnalytic;
    }> {
        return this.http
            .get<{
                data: {
                    broker: BrokerSimple;
                    priority_rating: {
                        rating_4: BrokerPerformanceAnalyticRating;
                        rating_5: BrokerPerformanceAnalyticRating;
                    };
                    properties_total: number;
                };
                status: string;
            }>(`${env.baseServerUrl}/insights/drill-down/broker`, {
                params: { broker_id: brokerId, ...params },
            })
            .pipe(
                map(response => ({
                    ...response.data.broker,
                    rate4: {
                        broker: response.data.broker,
                        rating: response.data.priority_rating.rating_4,
                        properties_total: response.data.properties_total,
                    },
                    rate5: {
                        broker: response.data.broker,
                        rating: response.data.priority_rating.rating_5,
                        properties_total: response.data.properties_total,
                    },
                })),
            );
    }

    public assignInvestorRequest(brokerId: number, investorIds: number[]) {
        return this.http.post(`${env.baseServerUrl}/brokers/${brokerId}/assign-investor`, {
            assign: true,
            investor_ids: investorIds,
        });
    }

    public sendInviteEmail(brokerId: number): Observable<string> {
        return this.http
            .post<{ status: 'success' }>(`${env.baseServerUrl}/brokers/${brokerId}/invite`, {
                registration_url: `${env.baseUrl}/auth/register`,
            })
            .pipe(map(response => response.status));
    }
}
